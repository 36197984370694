import React from "react"
import styled from "styled-components"
import Section from "../Section"

const JoinToCourseSection = ({
  text,
  color,
}: {
  text: string
  color?: string
}) => {
  const backgroundColor = color ? color : "#f8f7fa"

  return (
    <SectionJoinToCourse
      className="join-to-course"
      backgroundColor={backgroundColor}
    >
      <div className="container is-widescreen">
        <div className="columns is-vcentered">
          <div className="column is-6-desktop is-offset-3-desktop is-12-mobile">
            <div className="container">
              <div className="has-text-centered">
                <div className="level-item level-center">
                  <CourseButton
                    href="/szkola-rodzenia-z-anna-nowak-duda/#variants"
                    type="submit"
                    className="is-multiline align-self-end button is-large is-fullwidth is-primary has-text-weight-bold has-text-font-comfortaa"
                  >
                    {text}
                  </CourseButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SectionJoinToCourse>
  )
}

export default JoinToCourseSection

const CourseButton = styled.a`
  @media only screen and (min-device-width: 320px) and (max-device-width: 500px) {
    font-size: 1.35rem !important;
    padding-bottom: calc(1.2em - 1px);
    padding-left: 0.85em;
    padding-right: 0.85em;
    padding-top: calc(1.2em - 1px);
    height: auto;
    font-family: "Comfortaa", Arial, Helvetica, cursive;
    font-weight: bold;
  }

  @media only screen and (min-device-width: 310px) and (max-device-width: 330px) {
    font-size: 1rem !important;
  }
`

const SectionJoinToCourse = styled(Section)<{ backgroundColor: string }>`
  padding: 20px;
  color: #f55691;
  background-color: ${props => props.backgroundColor};
`
