import styled from "styled-components"

export const VideoContainer = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  margin-left: -10px;
  margin-right: -10px;
  height: 0;
  overflow: hidden;

  & iframe {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    height: 95%;
  }
`
