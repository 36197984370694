import React from "react"
import "../../../styles/course.scss"
import CourseNav from "../../../components/course/Nav"
import Footer from "../../../components/course/sections/Footer"
import JoinToCourseSection from "../../../components/course/sections/JoinToCourse"
import { VideoContainer } from "../../../components/course/VideoContainer"
import SEO from "../../../components/seo"
import { graphql } from "gatsby"

const DemoLesson = ({ data }: { data: { file: any } }) => {
  const { file } = data
  return (
    <div id={"course-container"}>
      <SEO
        title={
          "Lekcja Demo Szkoły Rodzenia z Anną Nowak-Dudą | Bez wychodzenia z domu, ponad 700 zadowolonych Mam"
        }
        description={
          "Kompletna, profesjonalna szkoła rodzenia online. Bez stresu, dojazdów i problemów. Wsparcie także po porodzie."
        }
        image={file.childImageSharp.fluid.src}
        article={false}
      />
      <CourseNav />
      <section className="is-white" id="main">
        <div className="container is-widescreen">
          <div className="columns is-vcentered">
            <div className="column is-6-desktop is-offset-3-desktop">
              <div className="container">
                <h1 className="title is-3 has-text-font-comfortaa has-text-black-ter has-text-centered mb-4">
                  <strong className="has-text-primary">Lekcja demo </strong>
                </h1>
              </div>
            </div>
          </div>
        </div>
        <div className="container is-widescreen">
          <VideoContainer>
            <iframe
              src="https://player.vimeo.com/video/352890200"
              width="640"
              height="360"
              frameBorder="0"
              allow="autoplay; fullscreen"
              allowFullScreen
            />
          </VideoContainer>
        </div>
      </section>
      <JoinToCourseSection
        text={"Zapisz się na kurs!"}
        color={"rgb(255,251,250)"}
      />
      <Footer />
    </div>
  )
}

export default DemoLesson

export const query = graphql`
  query {
    file(relativePath: { eq: "course-cover.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`
